<template>
  <!-- badge -->
  <div>
    <b-list-group class="mb-1">
      <b-list-group-item
        class="d-flex justify-content-between align-items-center cursor-pointer"
        href="https://judgments.ecourts.gov.in/pdfsearch/index.php"
        target="_blank"
        variant="success"
      >
        <span>Judgment Search (SC and High Court)</span>
        <b-badge variant="primary" pill class="badge-round">
          Open
          <feather-icon icon="ExternalLinkIcon" size="16" class="mr-25" />
        </b-badge>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center cursor-pointer"
        href="https://digiscr.sci.gov.in/"
        target="_blank"
        variant="success"
      >
        <span>Digital Supreme Court Reports</span>
        <b-badge variant="primary" pill class="badge-round">
          Open
          <feather-icon icon="ExternalLinkIcon" size="16" class="mr-25" />
        </b-badge>
      </b-list-group-item>
    </b-list-group>
    <b-list-group>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center cursor-pointer"
        href="https://pay.ecourts.gov.in/epay/"
        target="_blank"
        variant="info"
      >
        <span>E-Pay (eCourts Digital Payments)</span>
        <b-badge variant="primary" pill class="badge-round">
          Open
          <feather-icon icon="ExternalLinkIcon" size="16" class="mr-25" />
        </b-badge>
      </b-list-group-item>

      <b-list-group-item
        class="d-flex justify-content-between align-items-center cursor-pointer"
        href="https://njdg.ecourts.gov.in/njdgnew/index.php"
        target="_blank"
        variant="info"
      >
        <span>High Court NJDG</span>
        <b-badge variant="primary" pill class="badge-round">
          Open
          <feather-icon icon="ExternalLinkIcon" size="16" class="mr-25" />
        </b-badge>
      </b-list-group-item>

      <b-list-group-item
        class="d-flex justify-content-between align-items-center cursor-pointer"
        href="https://efiling.ecourts.gov.in"
        target="_blank"
        variant="info"
      >
        <span>E-Filling</span>
        <b-badge variant="primary" pill class="badge-round">
          Open
          <feather-icon icon="ExternalLinkIcon" size="16" class="mr-25" />
        </b-badge>
      </b-list-group-item>

      <b-list-group-item
        class="d-flex justify-content-between align-items-center cursor-pointer"
        href="https://www.indiacode.nic.in"
        target="_blank"
        variant="info"
      >
        <span>India Code</span>
        <b-badge variant="primary" pill class="badge-round">
          Open
          <feather-icon icon="ExternalLinkIcon" size="16" class="mr-25" />
        </b-badge>
      </b-list-group-item>

      <b-list-group-item
        class="d-flex justify-content-between align-items-center cursor-pointer"
        href="https://ecourts.gov.in/ecourts_home/static/informationletter.php"
        target="_blank"
        variant="info"
      >
        <span>Forms of Advocates</span>
        <b-badge variant="primary" pill class="badge-round">
          Open
          <feather-icon icon="ExternalLinkIcon" size="16" class="mr-25" />
        </b-badge>
      </b-list-group-item>

      <b-list-group-item
        class="d-flex justify-content-between align-items-center cursor-pointer"
        href="https://vcourts.gov.in/virtualcourt/"
        target="_blank"
        variant="info"
      >
        <span>Virtual Courts</span>
        <b-badge variant="primary" pill class="badge-round">
          Open
          <feather-icon icon="ExternalLinkIcon" size="16" class="mr-25" />
        </b-badge>
      </b-list-group-item>

      <b-list-group-item
        class="d-flex justify-content-between align-items-center cursor-pointer"
        href="https://ecommitteesci.gov.in"
        target="_blank"
        variant="info"
      >
        <span>E-Committee Supreme Court</span>
        <b-badge variant="primary" pill class="badge-round">
          Open
          <feather-icon icon="ExternalLinkIcon" size="16" class="mr-25" />
        </b-badge>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BListGroup,
    BListGroupItem
  }
}
</script>
